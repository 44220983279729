<template>
	<BaseLoading v-if="permissions.isLoading" />
	<FormUserRolePermissionSetting
		v-else
		:is-submitting="create.isCreating"
		:permissions="permissions.data"
		@onConfirm="handleConfirm"
	/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormUserRolePermissionSetting from '@/components/FormUserRolePermissionSetting.vue';

export default {
	name: 'UserRoleCreate',
	components: {
		FormUserRolePermissionSetting,
	},
	computed: {
		...mapState('roles', {
			create: 'create',
			permissions: 'permissions',
		}),
	},
	async created() {
		await this.getPermissions();
	},
	methods: {
		...mapActions({
			getPermissions: 'roles/getPermissions',
			createRole: 'roles/createRole',
		}),
		async handleConfirm(formData) {
			const params = {
				...formData,
			};
			await this.createRole(params);
		},
	},
};
</script>
