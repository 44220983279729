<template>
	<div class="main-wrapper">
		<CRow>
			<CCol>
				<CInput
					v-model="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					label="Group name*"
					placeholder="e.g. Store staff, Customer support"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<CInput
					v-model="$v.formData.description.$model"
					label="Description"
					placeholder="e.g. Members can manage all the product catalogs"
				/>
			</CCol>
		</CRow>
		<CRow class="py-2 px-3">
			<CCol md="8">
				<div class="label">
					Permission
				</div>
			</CCol>
			<CCol md="2">
				<div class="label">
					View
				</div>
			</CCol>
			<CCol md="2">
				<div class="label">
					Manage
				</div>
			</CCol>
		</CRow>
		<CRow class="mb-5">
			<CCol>
				<div class="border border-gray-400 rounded pb-4">
					<CRow>
						<CCol>
							<div class="bg-gray-100">
								<CRow class="py-2">
									<CCol md="8">
										<div class="label ml-3">
											Select all
										</div>
									</CCol>
									<CCol md="2">
										<BaseIndeterminateCheckbox
											:checked="isEveryChecked('view')"
											:indeterminate="isIndeterminate('view')"
											@update:checked="handleSelectAllView"
										/>
									</CCol>
									<CCol md="2">
										<BaseIndeterminateCheckbox
											:checked="isEveryChecked('manage')"
											:indeterminate="isIndeterminate('manage')"
											@update:checked="handleSelectAllManage"
										/>
									</CCol>
								</CRow>
							</div>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<div
								v-for="(parent, parentIndex) in $v.formData.permissions.$model"
								:key="parentIndex"
								class="roles py-2"
							>
								<CRow>
									<CCol md="8">
										<div class="color-black-45 font-weight-normal ml-4">
											<span class="color-gray-900">{{ parent.name }}</span>
										</div>
									</CCol>
									<CCol
										v-if="parent.key"
										md="2"
									>
										<div>
											<CInputCheckbox
												:checked.sync="parent.permissions.view"
												custom
											/>
										</div>
									</CCol>
									<CCol
										v-if="parent.key"
										md="2"
									>
										<div>
											<CInputCheckbox
												:checked.sync="parent.permissions.manage"
												custom
											/>
										</div>
									</CCol>
									<CCol md="12">
										<CRow
											v-for="(child, childIndex) in parent.children"
											:key="childIndex"
											class="mb-1"
										>
											<CCol md="8">
												<div class="ml-3 color-black-45 font-weight-normal ml-5">
													{{ child.name }}
												</div>
											</CCol>
											<CCol v-if="child.permissions.view !== undefined" md="2">
												<div>
													<CInputCheckbox
														:checked.sync="child.permissions.view"
														custom
													/>
												</div>
											</CCol>
											<CCol v-if="child.permissions.manage !== undefined" md="2">
												<div>
													<CInputCheckbox
														:checked.sync="child.permissions.manage"
														custom
													/>
												</div>
											</CCol>
										</CRow>
									</CCol>
								</CRow>
							</div>
						</CCol>
					</CRow>
				</div>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:is-edit="isEdit"
			:disabled-confirm="isSubmitting"
			:disabled-cancel="isSubmitting"
			:remove-text="isEdit ? 'Remove group' : null"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
			@onRemove="handleRemove"
		/>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { scrollToTop } from '../assets/js/helpers';
import { ROUTE_PERMISSION_KEY, ROUTE_PERMISSION_TYPE } from '../enums/permissions';

export default {
	name: 'FormUserRolePermissionSetting',
	validations: {
		formData: {
			name: {
				required,
			},
			description: {},
			permissions: {},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		permissions: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			formData: {
				name: '',
				description: '',
				permissions: [],
			},
		};
	},
	created() {
		this.formData.name = this.name;
		this.formData.description = this.description;
		this.formData.permissions = this.permissions;
	},
	methods: {
		handleConfirm() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}
			this.$emit('onConfirm', this.formData);
		},
		handleCancel() {
			this.$router.push({ name: 'UserRole' });
		},
		handleRemove() {
			this.$emit('onRemove');
		},
		checkPermissions(type, checked) {
			this.permissions.forEach((parentPermission) => {
				if (parentPermission.permissions) {
					parentPermission.permissions[type] = checked;
				}

				if (parentPermission.children) {
					parentPermission.children.forEach((childPermission) => {
						// Fixed manage branch shippment display on role and permission
						if (childPermission.key === ROUTE_PERMISSION_KEY.BRANCH_SHIPMENTS && type === ROUTE_PERMISSION_TYPE.MANAGE) {
							return;
						}

						childPermission.permissions[type] = checked;
					});
				}
			});
		},
		getCheckedState(type) {
			let state = [];
			this.permissions.forEach((parentPermission) => {
				if (parentPermission.permissions) {
					state = [
						...state,
						parentPermission.permissions[type],
					];
				}

				if (parentPermission.children) {
					parentPermission.children.forEach((childPermission) => {
						state = [
							...state,
							childPermission.permissions[type],
						];
					});
				}
			});
			return state;
		},
		isIndeterminate(type) {
			const state = this.getCheckedState(type);
			return state.some((bool) => bool) && !state.every((bool) => bool);
		},
		isEveryChecked(type) {
			const state = this.getCheckedState(type);
			return state.every((bool) => bool);
		},
		handleSelectAllView(checked) {
			this.checkPermissions('view', checked);
		},
		handleSelectAllManage(checked) {
			this.checkPermissions('manage', checked);
		},
	},
};
</script>

<style lang="scss" scoped>
	.roles {
		font-size: rem($font-size-label);
	}
</style>
